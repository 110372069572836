import React from 'react';
import styles from './features.scss';
import baseStyles from '@/Framework/UI/Pages/Landing/Sections/base.scss';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import SectionContainer from '@/Framework/UI/Pages/Landing/Sections/SectionContainer';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import { Icon, IconType } from '@dealroadshow/uikit';

interface IProps {
  title: string,
  description: string,
  collection: string[][],
  isExploreButtonExists: boolean,
}

const renderFeaturesColumn = (item, i) => (
  <div className={ styles.featuresColumn } key={ i }>
    { item.map(renderCollectionItem) }
  </div>
);

const renderCollectionItem = (item, i) => (
  <div className={ styles.feature } key={ i }>
    <div className={ styles.featureIcon }>
      <Icon type={ IconType.checkCircle } />
    </div>
    <div className={ styles.featureTitle }>{ item }</div>
  </div>
);

const Features = (props: IProps) => (
  <SectionContainer size="small" narrow>
    <div className={ styles.features }>
      <SectionContainer size="small">
        <div className={ baseStyles.sectionTitle }>{ props.title }</div>
        <div className={ baseStyles.sectionDescription }>{ props.description }</div>
      </SectionContainer>
      <div className={ cardStyles.cardContainer }>
        <div className={ styles.featuresContainer }>
          <div className={ styles.featuresRow }>
            { props.collection.map(renderFeaturesColumn) }
          </div>
          { props.isExploreButtonExists
            ? (
              <div className={ styles.featuresCta }>
                <Button
                  variant={ variantTypes.firm }
                  className={ styles.featuresCtaBtn }
                  title="Explore Our Features"
                  dataTest="featuresFirmButton"
                />
              </div>
            )
            : null }
        </div>
      </div>
    </div>
  </SectionContainer>
);

export default Features;
